import React from "react"
import { Link } from "gatsby"
import Layout from "components/layout"
import Seo from "components/seo"
import * as FadeIn from "components/fadein"
import { Fv } from "components/fv"

const CleaningDenimPage = () => {
  return (
    <Layout>
      <Seo title="Denim Cleaning" />
      <div className="denim-cleaning design-style">
        <Fv
          pcFv="https://balukoweb.blob.core.windows.net/images/laundry-service/denim/mv-img.jpg"
          spFv="https://balukoweb.blob.core.windows.net/images/laundry-service/denim/sp-mv-img.jpg"
          fvTitle="Denim Cleaning"
          color="white"
        />
        <FadeIn.Up>
          <div className="lead-area">
            {/* PC */}
            <h2 className="pc title tx-center">
              デニムを「洗う」だけじゃない。
              <br />
              あなただけの1本に「育てる」こだわりのメニュー
            </h2>
            {/* SP-L */}
            <h2 className="sp title tx-center">
              デニムを「洗う」だけじゃない。
              <br />
              あなただけの1本に「育てる」
              <br />
              こだわりのメニュー
            </h2>
            {/* PC */}
            <p className="pc lead tx-center fw-b">
              デニム加工工場で、商品企画開発はもちろん、洗い加工から染工場の現場にいたるまでの工程に携わった、
              <br />
              ジーンズソムリエが手掛けるデニム専用クリーニング。
              <br />
              風合い、シルエットなど、ご希望の仕上がりに合わせてメニューをお選びいただけます。
              <br />
            </p>
            {/* SP */}
            <p className="sp lead fw-b">
              デニム加工工場で、商品企画開発はもちろん、洗い加工から染工場の現場にいたるまでの工程に携わった、ジーンズソムリエが手掛けるデニム専用クリーニング。風合い、シルエットなど、ご希望の仕上がりに合わせてメニューをお選びいただけます。
            </p>
          </div>
        </FadeIn.Up>

        <FadeIn.Up>
          <div className="title-area-style tx-center">
            <h2 className="title e">Price</h2>
            <p className="sub-title fw-b">価格</p>
          </div>

          <div className="main-contents">
            <div className="course-list">
              <div className="course-list-inner">
                <h2 className="title tx-center fw-b">コース</h2>
                <div className="course-list-contents">
                  <div className="course-list-contents-item">
                    <div className="course-list-contents-left">
                      <div>
                        <p className="flex-item">
                          <span className="course-name fw-b">スタンダード</span>
                          <span className="course-price fw-b">¥880</span>
                        </p>
                        <p className="course-about">
                          履き込んで馴染んだ風合いを出す
                        </p>
                      </div>
                    </div>
                    <div className="course-list-contents-right">
                      <p className="course-detail">
                        自然な色落ちやアタリ、エイジング感を演出したいデニムに。
                      </p>
                    </div>
                  </div>
                  <div className="course-list-contents-item">
                    <div className="course-list-contents-left">
                      <div>
                        <p className="flex-item">
                          <span className="course-name fw-b">スペシャル</span>
                          <span className="course-price fw-b">¥1,650</span>
                        </p>
                        {/* PC */}
                        <p className="course-about pc">
                          デニムの色落ちやダメージを
                          <br />
                          最小限に留める
                        </p>
                        {/* SP */}
                        <p className="course-about sp">
                          デニムの色落ちやダメージを最小限に留める
                        </p>
                      </div>
                    </div>
                    <div className="course-list-contents-right">
                      <p className="course-detail">
                        洗濯による色落ちやダメージを避けたいデニムの定期的な
                        <br />
                        メンテナンスに。
                        <br />
                        <span className="note">
                          ※色落ちやダメージを完全に防ぐことをお約束するコースではありません。
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="course-list-contents-item">
                    <div className="course-list-contents-left">
                      <div>
                        <p className="flex-item">
                          <span className="course-name fw-b">デサイジング</span>
                          <span className="course-price fw-b">¥2,750</span>
                        </p>
                        <p className="course-about">
                          糊を落とし、デニムを風合い良く仕上げる
                        </p>
                      </div>
                    </div>
                    <div className="course-list-contents-right">
                      <p className="course-detail">
                        リジットデニムのファースト洗いに。
                      </p>
                    </div>
                  </div>
                  <div className="course-list-contents-item">
                    <div className="course-list-contents-left">
                      <div>
                        <p className="flex-item">
                          <span className="course-name fw-b">ドライ</span>
                          <span className="course-price fw-b">¥880</span>
                        </p>
                        <p className="course-about">水洗いを避けたいデニムに</p>
                      </div>
                    </div>
                    <div className="course-list-contents-right">
                      <p className="course-detail">
                        洗濯表示が水洗い×になっているなど、
                        <br />
                        通常のドライクリーニングを行いたいデニムに。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className="annotation">
              ※価格はすべて税込です。
              <br />
              ※素材や損傷が激しいものなど、一部お受けできないものがございます。
              <br />
            </p>
            <div className="title-area-style tx-center">
              <h2 className="title e">Store</h2>
              <p className="sub-title fw-b">取扱い店舗</p>
            </div>
            <div className="store-contents">
              <div className="store-contents-inner">
                <ul className="store-contents-list">
                  <li className="store-contents-item">
                    <div className="store-contents-item-box">
                      <img
                        className="lazy pc"
                        srcSet="https://balukoweb.blob.core.windows.net/images/cleaning/pc-store01-clipped.jpg"
                        width={413}
                        alt=""
                        loading="lazy"
                      />
                      <img
                        className="lazy sp"
                        srcSet="https://balukoweb.blob.core.windows.net/images/cleaning/pc-store01-clipped.jpg"
                        width="100%"
                        alt=""
                        loading="lazy"
                      />
                      <div className="store-contents-item-box-detail">
                        <p className="store-name fw-b">代々木上原</p>
                        <p className="store-address">東京都渋谷区上原3-29-2</p>
                        <Link className="store-link" to="/baluko-uehara/">
                          店舗詳細
                        </Link>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </FadeIn.Up>
      </div>
    </Layout>
  )
}
export const Head = () => (
  <Seo
    title="Denim Cleaning ー デニムを「洗う」だけじゃない。"
    description="あなただけの1本に「育てる」こだわりのデニムクリーニング。デニム加工工場で13年間経験を積んだジーンズソムリエ高野氏が手掛けます。風合い、シルエットなど、ご希望の仕上がりに合わせてメニューをお選びいただけます。"
  />
)
export default CleaningDenimPage
